import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo/seo'
import '../assets/scss/success-error.scss'
import Button from '../components/button/button'

const Content = ({ location }) => {
    return (
        <Layout>
            <Seo
                title="Sorry, we couldn’t find that page - South Lane"
                pathname={location.pathname}
            />
            <div className="form-wrapper">
                <div className="success-error">
                    <div className="success-error__icon-container">
                        <i className="ico ico--cross ico--cross-xl ico--danger">
                            <i />
                        </i>
                    </div>

                    <div className="form-wrapper__heading success-error__heading">
                        Sorry, we couldn’t find&nbsp;that&nbsp;page
                    </div>

                    <p className="success-error__message p-l">
                        You may have mistyped the address
                        <br /> or the page has moved.
                    </p>

                    <Button
                        text="Back to the homepage"
                        href="/"
                        className="button--l-mobile"
                    />
                </div>
            </div>
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
